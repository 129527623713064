import React from 'react'
import { CONTENT } from '../../content'
// import * as styles from "./Consultations.module.scss"
import { Layout } from '../../layouts'
import Hero from '../../components/Blocks/Hero/Hero'
import SiteTitle from '../../components/Blocks/SiteTitle/SiteTitle'
import Seo from '../../components/seo'

const Consultations = () => (
  <Layout>
    <Seo
      title={CONTENT.CONSULTATION.WHY.title}
      description="Le désir de voir la situation qui vous fait souffrir ou vous pose problème évoluer vers plus d’apaisement se suffit à lui-même pour motiver une consultation"
    />
    <section id="consultations">
      <SiteTitle />
      <Hero>
        <header>
          <h1>{CONTENT.CONSULTATION.WHY.title}</h1>
        </header>
        <div>
          {CONTENT.CONSULTATION.WHY.content.map((content, index) => (
            <p key={index} dangerouslySetInnerHTML={{ __html: content }} />
          ))}
        </div>
      </Hero>
    </section>
  </Layout>
)

export default Consultations
